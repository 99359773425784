import React from 'react';


const Register = () => {
  return (
    <>
 
  <header className="text-black text-center py-5">
    <div className="container">
      <h1>Teacher Registration</h1>
      <p className="lead">Join Student Grows to create, manage, and share educational content with ease.</p>
    </div>
  </header>

  
  <section className="py-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card shadow">
            <div className="card-body">
              <h3 className="card-title text-center mb-4">Create Your Account</h3>
              <form>
               
                <div className="mb-3">
                  <label for="fullName" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fullName" placeholder="Enter your full name" required/>
                </div>
               
                <div className="mb-3">
                  <label for="email" className="form-label">Email Address</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter your email address" required/>
                </div>
               
                <div className="mb-3">
                  <label for="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number"/>
                </div>
              
                <div className="mb-3">
                  <label for="password" className="form-label">Password</label>
                  <input type="password" className="form-control" id="password" placeholder="Create a password" required/>
                </div>
               
                <div className="mb-3">
                  <label for="confirmPassword" className="form-label">Confirm Password</label>
                  <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm your password" required/>
                </div>
             
                <div className="mb-3">
                  <label for="institution" className="form-label">Institution Name</label>
                  <input type="text" className="form-control" id="institution" placeholder="Enter your institution name"/>
                </div>
              
                <div className="mb-3">
                  <label for="subject" className="form-label">Subject Specialization</label>
                  <select className="form-select" id="subject">
                    <option selected>Choose...</option>
                    <option value="math">Mathematics</option>
                    <option value="science">Science</option>
                    <option value="english">English</option>
                    <option value="history">History</option>
                    <option value="other">Other</option>
                  </select>
                </div>
               
                <div className="mb-3 form-check">
                  <input type="checkbox" className="form-check-input" id="terms" required/>
                  <label className="form-check-label" for="terms">I agree to the <a href="#">terms and conditions</a>.</label>
                </div>
              
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">Register</button>
                </div>
              </form>
              <p className="text-center mt-3">Already have an account? <a href="#">Log in here</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  
    </>
  );
};

export default Register;
