import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import SidebarPdf from "../../components/SidebarPdf";
import { fetchNewQuestionsPdf } from '../../api';

const NewQuestionPdfList = () => {
  const [newQuestions, setNewQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  

  // Fetch newQuestions from API on load
  useEffect(() => {

    const getNewQuestionsPdf = async()=>{
      if(selectedClassId != 0 && selectedSubjectId != 0){
        try{
          const response = await fetchNewQuestionsPdf(selectedClassId, selectedSubjectId)
          console.log('response')
          console.log(response)
          setNewQuestions(response.data.data)  
        }
        catch(error){
          console.log(error)
        }
      }
    }

    getNewQuestionsPdf()
    
  }, [selectedClassId, selectedSubjectId]);


  
  return (
    <div className="wrapper">
    
      <div className="row">
          <SidebarPdf></SidebarPdf>
          <div className="container mt-4 col-md-9 col-lg-10">
            <h3>NewQuestion Pdf List</h3>

            <div className="class-info">
                <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
                <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
            </div>


            {/* NewQuestion Table */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                 
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Status</th>
                  <th>Download Count</th>
                  <th>Download Link</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {newQuestions.map((newQuestion, index) => (
                  <tr key={newQuestion.id}>
                    <td>{index + 1}</td>
              
                    <td>{newQuestion.title}</td>
                    <td>{newQuestion.subtitle}</td>
                    <td>{newQuestion.status}</td>
                    <td>{newQuestion.downloadCount}</td>
                    <td>
                      {newQuestion.downloadLink == '' ? (<div></div>) : (
                        <a href={newQuestion.downloadLink} download={newQuestion.downloadLink} target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary" >Download PDF </a>
                      )}
                    
                    </td>
                    {/* <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteNewQuestion(NewQuestion.id)}
                      >
                        Delete
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
      </div>
    </div>
  );
};

export default NewQuestionPdfList;
