import { fetchTopics } from '../api';

// Action Types
export const GET_TOPICS = 'GET_TOPICS';
export const SELECT_TOPIC = 'SELECT_TOPIC';
export const RESET_TOPIC = 'RESET_TOPIC';
export const RESET_TOPICS = 'RESET_TOPICS';
export const SELECT_TOPIC_ID = 'SELECT_TOPIC_ID';


// Action Creators
export const getTopics = (subjectId) => async (dispatch) => {
  try {
    const response = await fetchTopics(subjectId);
    dispatch({ type: GET_TOPICS, payload: response.data });
  } catch (error) {
    console.error('Error fetching topics:', error);
  }
};

export const selectTopic = (selectedTopic) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_TOPIC, payload: selectedTopic });
  } catch (error) {
    console.error('Error updating topic:', error);
  }
};

export const selectTopicId = (selectedTopicId) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_TOPIC_ID, payload: selectedTopicId });
  } catch (error) {
    console.error('Error updating subtopic:', error);
  }
};



