import { fetchSubTopics } from '../api';

// Action Types
export const GET_SUBTOPICS = 'GET_SUBTOPICS';
export const SELECT_SUBTOPIC = 'SELECT_SUBTOPIC';
export const SELECT_SUBTOPIC_ID = 'SELECT_SUBTOPIC_ID';
export const RESET_SUBTOPICS = 'RESET_SUBTOPICS';


// Action Creators
export const getSubTopics = (topicId) => async (dispatch) => {
  try {
    const response = await fetchSubTopics(topicId);
    console.log('response')
    console.log(response)
    dispatch({ type: GET_SUBTOPICS, payload: response.data.data });
  } catch (error) {
    console.error('Error fetching topics:', error);
  }
};

export const selectSubTopic = (selectedSubTopic) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_SUBTOPIC, payload: selectedSubTopic });
  } catch (error) {
    console.error('Error updating subtopic:', error);
  }
};

export const selectSubTopicId = (selectedSubTopicId) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_SUBTOPIC_ID, payload: selectedSubTopicId });
  } catch (error) {
    console.error('Error updating subtopic:', error);
  }
};


