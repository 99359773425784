import React from 'react';


const Tutorial = () => {
  return (
    <>
 

  <header class="text-black text-center py-5">
    <div class="container">
      <h1>Tutorials</h1>
      <p class="lead">Learn how to create, customize, and share your questions, assignments, and worksheets with ease.</p>
    </div>
  </header>


  <section class="py-5">
    <div class="container">
      <h2 class="text-center mb-4">Step-by-Step Tutorials</h2>
      <div class="row">
      
        <div class="col-lg-6 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Video Tutorial: Getting Started</h5>
              <p class="card-text">Watch this quick video to learn the basics of using CreatorPro to create and manage your educational content.</p>
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/example" title="Getting Started Tutorial" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-lg-6 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Written Tutorial: Creating Your First Worksheet</h5>
              <p class="card-text">Follow this step-by-step guide to create your first worksheet using CreatorPro's intuitive interface.</p>
              <ul>
                <li>Login to your account.</li>
                <li>Navigate to the "Worksheets" section.</li>
                <li>Click "Create New Worksheet" and fill in the details.</li>
                <li>Add questions, images, or files as needed.</li>
                <li>Save and preview your worksheet before sharing.</li>
              </ul>
              <a href="#" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
      </div>

   
      <div class="row">
        <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Tutorial"/>
            <div class="card-body">
              <h5 class="card-title">How to Create Assignments</h5>
              <p class="card-text">Learn how to create engaging and personalized assignments for your students.</p>
              <a href="#" class="btn btn-primary">Watch Video</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Tutorial"/>
            <div class="card-body">
              <h5 class="card-title">Using Custom Templates</h5>
              <p class="card-text">Discover how to use and edit templates to speed up content creation.</p>
              <a href="#" class="btn btn-primary">Read Guide</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-4">
          <div class="card">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Tutorial"/>
            <div class="card-body">
              <h5 class="card-title">Sharing Your Content</h5>
              <p class="card-text">A quick guide to exporting and sharing your questions, assignments, and worksheets.</p>
              <a href="#" class="btn btn-primary">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  
    </>
  );
};

export default Tutorial;
