import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_PAPERS, SELECT_PAPER, UPDATE_PAPER_SECTION } from '../actions/paperActions';

const CreateSection = () => {
    // const [sectionDetail, setSectionDetail] = useState('');
    const {selectedPaper} = useSelector((state) => state.papers);
    const dispatch = useDispatch();
    const sectionRef = useRef()
    
    const addSection = ()=>{
        let section = {
            "id": selectedPaper.sections.length + 1,
            "section_name": sectionRef.current.value,
            "questions": [],
            "worksheets": [],
            "newQuestions": []
        }
        // setSections(prevItems => [...prevItems, section]);
       
        dispatch({
          'type': UPDATE_PAPER_SECTION,
          'payload': [...selectedPaper.sections, section]
        })

        // setSectionDetail('')
        sectionRef.current.value = ''
    }

    return(
        <div className="row mb-2">
            <div className="col-md-10">
                <input type="text" className="form-control" ref={sectionRef}  placeholder="Enter section" required/>
            </div>
            <div className="col-md-2">
                <button className="btn btn-primary text-end btn-sm" onClick={addSection}>Save</button>
            </div>
        </div>
    )
    

   
};

export default CreateSection