import React from 'react';


const Blog = () => {
  return (
    <>
 
 
  <header class="text-black text-center py-5">
    <div class="container">
      <h1>Blog</h1>
      <p class="lead">Stay updated with the latest tips, tricks, and insights for creating amazing educational content.</p>
    </div>
  </header>

 
  <section class="py-5">
    <div class="container">
      <h2 class="text-center mb-4">Recent Posts</h2>
      <div class="row">
      
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Blog Post"/>
            <div class="card-body">
              <h5 class="card-title">5 Tips for Crafting Engaging Questions</h5>
              <p class="card-text">Learn how to create thought-provoking and engaging questions for your assignments and worksheets.</p>
              <a href="#" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
      
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Blog Post"/>
            <div class="card-body">
              <h5 class="card-title">Top 10 Worksheet Templates for Teachers</h5>
              <p class="card-text">Explore our collection of customizable worksheet templates to save time and enhance learning.</p>
              <a href="#" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
       
        <div class="col-md-4 mb-4">
          <div class="card h-100">
            <img src="https://via.placeholder.com/350x200" class="card-img-top" alt="Blog Post"/>
            <div class="card-body">
              <h5 class="card-title">Using Images in Assignments</h5>
              <p class="card-text">Discover creative ways to incorporate images into your assignments for better student engagement.</p>
              <a href="#" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
      </div>

      
      <nav aria-label="Blog Pagination" class="mt-4">
        <ul class="pagination justify-content-center">
          <li class="page-item disabled">
            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </section>

  
    </>
  );
};

export default Blog;
