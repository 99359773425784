import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table } from 'react-bootstrap';
import SidebarPdf from "../../components/SidebarPdf";
import { useSelector, useDispatch } from 'react-redux';
import { fetchQuestionTemplates, deleteQuestionTemplate } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';

const QuestionTemplates = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    institute_id: '',
    class_id: '',
    subject_id: '',
    status: '',
    title: '',
    pdf: null,
  });

  const [editMode, setEditMode] = useState(false);

  //const [questions, setQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);

  // Fetch sample questions
  const fetchQuestions = async () => {
    try {
      const response = await fetchQuestionTemplates(selectedClassId, selectedSubjectId);
      setQuestions(response.data.data);
    } catch (error) {
      console.error('Error fetching sample Questions:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [selectedClassId, selectedSubjectId]);

  

  const handleEdit = (id) => {
    navigate(`/edit-question-template/${id}`)
  };

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (!userConfirmed) {
      return false;
    } 
    try {
      await deleteQuestionTemplate(id)
      alert('Sample Question deleted successfully!');
      fetchQuestions();
    } catch (error) {
      console.error('Error deleting sample Question:', error);
      alert('Failed to delete sample Question.');
    }
  };

  return (
    <div className="wrapper">
    
    <div className="row">
        <SidebarPdf></SidebarPdf>
        <div className="container mt-4 col-md-9 col-lg-10">
          <h3>Question Templates</h3>

          <div className="class-info">
              <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
              <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
          </div>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Header Image</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {questions.map((question, i) => (
                    <tr key={question.id}>
                    <td>{i+1}</td>
                    <td>{question.title}</td>
                    <td>
                      {question.headerImage == '' ? (<div></div>) : (
                        <a href={question.headerImage} download={question.headerImage} target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary" >Download PDF </a>
                      )}
                    
                    </td>
                    <td>{question.status}</td>
                    <td>
                         <Button
                            style={{marginRight: 10}}
                            variant="primary"
                            size="sm"
                            onClick={() => { navigate(`/question-template/${question.id}`);}}
                          >
                            View
                        </Button>
                        <Button variant="warning" onClick={() => handleEdit(question.id)} size="sm">
                        Edit
                        </Button>{' '}
                        <Button variant="danger" onClick={() => handleDelete(question.id)} size="sm">
                        Delete
                        </Button>
                    </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
      </div>
    </div>
  );
};

export default QuestionTemplates;
