import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getNewQuestions} from '../../actions/newQuestionActions'
import '../../styles/new-questions.css'

import Sidebar from '../../components/Sidebar';

const NewQuestions = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {newQuestionList} = useSelector((state) => state.newQuestions);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics);
  const { teacher } = useSelector((state) => state.auth);

  const handleEdit = (id) => {
    navigate(`/edit-newquestion/${id}`);
  };
  

  
  
  return (
    <div className="wrapper">
    
      <div className="row">
          <Sidebar></Sidebar>
          <div className=" col-md-9 col-lg-10 content">
            
            
          <div className="col-md-12">
          <div className="content-section">
              
              <div className="container">
                
                  
                      <h2>Questions</h2>
                      
                    
                  
          {/* <div className="mb-3">
              <button className="btn btn-success">Add New Question</button>
          </div> */}
          
              
                  

                
                  {newQuestionList && newQuestionList.map((newQuestion, i)=>(

                      <div className="question" key={newQuestion.id}>

                                <div className="row">
                                  <div className="col-md-8"> 
                                    {newQuestion.title ? (
                                      <p><strong>{i+1}. {newQuestion.title}</strong> </p>
                                    ) : ('')}
                                  
                                    {newQuestion.contents.map((content)=>(
                                      <div className="row" key={content.id}>
                                          
                                        <div className="">
                                          <p style={{marginBottom:0, fontSize:18}}> <strong>{i+1}. {content.textContent}</strong></p>
                                          <div className="image-wrapper">
                                          
                                              {content.imageContent ? (
                                                    <img
                                                    src={`${content.imageContent}`}
                                                    alt="Preview"
                                                    className="img-fluid w-80"
                                                  />
                                                  ) : (
                                                    ''
                                                  )}
                                          </div>
                                         
                                        </div>
                                         
                                      </div>

                                      
                                        
                                        
                                    ))}  
                                  </div>
                                  <div className="col-md-4 ">
                                    <div className="action-buttons">
                                      {teacher && teacher.role == 'studentsTeacher' ? (
                                         <button className="btn btn-primary btn-sm" onClick={() => handleEdit(newQuestion.id)}>Edit</button>
                                      ) : ('')}
                                    </div>
                                  </div>
                                </div>
                        

                      </div>

                  ))}
                  
          
              
              </div>



          </div>
          </div>



          </div>
      </div>
    </div>
  );
};

export default NewQuestions;
