import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import '../../styles/PaperView.css'
import { useNavigate } from 'react-router-dom';
import { createNewQuestionPdf, fetchNewQuestionPdf } from '../../api';

  
  const NewQuestionHtmlView = (selectedPaper) => {

    const [newQuestionPdfData, setNewQuestionPdfData] = useState({
        subtopic_id: 0,
        newQuestions: '',
        schoolName: '',
        title: '',
        subtitle: '',
        downloadCount: 0,
    });

    const [pdfData, setPdfData] = useState(selectedPaper.paper)

    const [newQuestions, setNewQuestions] = useState([])
    let paperString = JSON.stringify(selectedPaper.paper)
    let paperData = JSON.parse(paperString)
   
    const contentRef = useRef(null);
    const navigate = useNavigate();
    const {selectedTopic} = useSelector((state) => state.topics);
    const {selectedSubTopic, selectedSubTopicId} = useSelector((state) => state.subtopics);
    const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
    const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);


    useEffect(() => {

        let newQuestions = [];
        paperData.sections.forEach(function (section, i) {
            section.newQuestions.forEach(function (newQuestion, i) {
                newQuestions.push(newQuestion)
            });
        });

        setNewQuestions([...newQuestions])

        setNewQuestionPdfData({
          ...newQuestionPdfData, 
          newQuestions: JSON.stringify([...newQuestions]),
          class_id: selectedClassId,
          className: selectedClass,
          subject_id: selectedSubjectId,
          subjectName: selectedSubject,
          schoolName: pdfData.school_name,
          title: pdfData.title,
          subtitle: pdfData.subtitle,
          downloadCount: 0,
        })
      
    }, []);
    
    // console.log('paperData') 
    // console.log(paperData) 

   

    console.log('newQuestions') 
    console.log(newQuestions) 

    const dataArray = [
      { id: 1, title: "Page 1", content: "Content for the first page." },
      { id: 2, title: "Page 2", content: "Content for the second page." },
      { id: 3, title: "Page 3", content: "Content for the third page." },
      { id: 4, title: "Page 4", content: "Content for the third page." },
      { id: 5, title: "Page 5", content: "Content for the third page." },
      { id: 6, title: "Page 6", content: "Content for the third page." },
      { id: 7, title: "Page 7", content: "Content for the third page." },
      { id: 8, title: "Page 8", content: "Content for the third page." },
      { id: 9, title: "Page 8", content: "Content for the third page." },
      { id: 10, title: "Page 8", content: "Content for the third page." },
    ];

    const addNewQuestionPaper = () => { 

      try{
        const response = createNewQuestionPdf(newQuestionPdfData) 
        console.log(response.data)
        alert('Message Sent')
        navigate('/newquestion-pdf-list')
      }
      catch(error){
        console.error("Error adding newQuestion:", error)
      }
      
    };

 

    if(paperData == null){
      return(
        <div className="container mt-5">
          <h1>Data not available</h1>
        </div>
      )
    }
   
    
    return (
      <div className="container"    >
       

       <div className='row' >
        <div className='col-md-2'>

              <div>
                {dataArray.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                    //   border: "2px dashed #000",
                      // padding: "20px",
                      // marginBottom: "20px",
                      height: "1090px", // A4 page height
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      pageBreakAfter: index === dataArray.length - 1 ? "auto" : "always",
                    }}
                  >
                    {/* <h1>{index + 1}</h1> */}
                  </div>
                ))}
            </div>

        </div>

        <div className='col-md-8' style={{maxWidth: '790px'}} >
            <div className='container' ref={contentRef} style={{paddingLeft: 30, paddingRight: 30}}>
                <header className="text-center mb-2">
                  <h2>{paperData.school_name}</h2>
                  <h4>{paperData.title}</h4> 
                  <p>{paperData.subtitle}</p>
                </header>
          
             
                  <section className="mb-2">
                      
                        {newQuestions.map((newQuestion, index) => {
                 
                            return ( 
                    
                                <div  key={newQuestion.id}  style={{'marginBottom':  Number(newQuestion.marginBottom) }}>
                                      {/* <div className="row">
                                          <div className="col-md-12">
                                                  <p><strong>{index + 1}. {newQuestion.title}</strong> </p>
                                          </div>
                                      </div> */}
                                  
                                      {newQuestion.contents.map((content, i)=>(
                                      <div className="row">
                                            <div className="col-md-12">
                                                  <div className="image-text-container">
                                                      <p>{index+1}.</p>
                                                      <div className="image-wrapper">
                                                      
                                                          {content.imageContent ? (
                                                                <img
                                                                src={`${content.imageContent}`}
                                                                alt="Preview"
                                                                className="img-fluid w-80"
                                                              />
                                                              ) : (
                                                                ''
                                                              )}
                                                      </div>
                                                  </div>
                                              
                                            </div>
                                        </div>
                              
                                  ))}  

                
                                </div>
                              
                            )

                        })}  
                      
                      
                      </section>
                   
            </div>
        </div>

        <div className='col-md-2'>  
                 <button className='btn btn-primary mt-4' onClick={addNewQuestionPaper}>Send Pdf Request</button>
        </div>
                
               
          

       </div>

      

       
       
  
       
      </div>
    );
  };
  

// Document Component
const NewQuestionView = () => {

  const {selectedPaper} = useSelector((state) => state.papers);
  
  return(
    <div>
        <div>
            <NewQuestionHtmlView paper={selectedPaper} ></NewQuestionHtmlView>
        </div>
    </div>
  );

}

  export default NewQuestionView;

