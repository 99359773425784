import { fetchNewQuestionsByTopic, createNewQuestion } from '../api';

// Action Types
export const GET_NEWQUESTIONS = 'GET_NEWQUESTIONS';
export const SELECT_NEWQUESTION = 'SELECT_NEWQUESTION';
export const CREATE_NEWQUESTION = 'CREATE_NEWQUESTION';

export const UPDATE_PAPER_SECTION = 'UPDATE_PAPER_SECTION';
export const UPDATE_PAPER_TITLE = 'UPDATE_PAPER_TITLE';
export const UPDATE_PAPER_SUBTITLE = 'UPDATE_PAPER_SUBTITLE';


// Action Creators
export const getNewQuestionsByTopic = (topicId) => async (dispatch) => {
  try {
    const response = await fetchNewQuestionsByTopic(topicId);
    console.log('NewQuestion')
   
    console.log(response.data.data)
    dispatch({ type: GET_NEWQUESTIONS, payload: response.data.data });
  } catch (error) {
    console.error('Error fetching NewQuestions:', error);
  }
};

export const postNewQuestion = (subTopicId, newQuestionString) => async (dispatch) => {
  try {
    const data = {
      "subtopic_id": subTopicId,
      "newQuestion_text": newQuestionString,
      "answer_text": "testing"
    }
    const response = await createNewQuestion(data);
    dispatch({ type: GET_NEWQUESTIONS, payload: response.data });
  } catch (error) {
    console.error('Error fetching NewQuestions:', error);
  }
};

export const selectNewQuestion = (selectedNewQuestion) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_NEWQUESTION, payload: selectedNewQuestion });
  } catch (error) {
    console.error('Error updating NewQuestion:', error);
  }
};


