import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';
import '../../styles/PaperView.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { questionPdfUpload, fetchQuestionTemplate } from '../../api';

  const MultipleChoiceQuestion = ({ question, options, name }) => {
    return (
      <div className="mb-3">
        <p>{question}</p>
        <ul className="list-unstyled">
          {options.map((option, index) => (
            <li key={index}>
              <input type="radio" name={name} /> {option}
            </li>
          ))}
        </ul>
      </div>
    ); 
  };
  
  const ShortAnswerQuestion = ({ question, name }) => {
    return (
      <div className="mb-3">
        <p>{question}</p>
      </div>
    );
  };

  const Wrapper = ({ id, questionString }) => {
    const question = JSON.parse(questionString);
    if(question.type == 'multiple'){
      question.options = JSON.parse(question.options)
    }
    console.log('question')
    console.log(question)
    return(
      <div>
          <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: question.question }} ></span></p>
          {question.type === "multiple" && (
                <ul>
                  {question.options.map((option, idx) => (
                    <li key={idx}>{option}</li>
                  ))}
                </ul>
          )}
      </div>
    )
  
     
  };
  
  
  const QuestionHtmlView = ({paperString}) => {
    
    
    const [questionNo, setQuestionNo] = useState(0)
    const [pixel, setPixel] = useState(0)
    const [worksheetStyle, setWorksheetStyle] = useState([])

    const contentRef = useRef(null);
    const marginBottomRef = useRef(null)
    const rowRef = useRef(null)
    const navigate = useNavigate();

    if(paperString == ''){
      return(
        <div className="container mt-5">
          <h1>Data not available</h1>
        </div>
      )
    } 



    let paper = JSON.parse(paperString)

    console.log('paper')
    console.log(paper)
    let templateData = JSON.parse(paper.templateData)

    console.log('templateData')
    console.log(templateData) 

    const dataArray = [
      { id: 1, title: "Page 1", content: "Content for the first page." },
      { id: 2, title: "Page 2", content: "Content for the second page." },
      { id: 3, title: "Page 3", content: "Content for the third page." },
      { id: 4, title: "Page 4", content: "Content for the third page." },
      { id: 5, title: "Page 5", content: "Content for the third page." },
      { id: 6, title: "Page 6", content: "Content for the third page." },
      { id: 7, title: "Page 7", content: "Content for the third page." },
      { id: 8, title: "Page 8", content: "Content for the third page." },
      { id: 9, title: "Page 8", content: "Content for the third page." },
      { id: 10, title: "Page 8", content: "Content for the third page." },
    ];

    const addStyle = ()=>{
      let rowNo = rowRef.current.value;
      let marginBottom = marginBottomRef.current.value
      let data = {
        'rowNo' : rowNo,
        'marginBottom': marginBottom
      }
      setWorksheetStyle([...worksheetStyle, data])
    }

    const deleteStyle = (index)=>{

      let styleArray = worksheetStyle.filter((value, i)=>{
          if(index != i){
            return value
          }
      })

      setWorksheetStyle([...styleArray])

    }

    const handleQuestionNo = (e)=>{
      setQuestionNo(e.target.value);
    }

    const handlePixel = (e)=>{
      setPixel(e.target.value)
    }

    const handleDownload = () => {
      const element = contentRef.current;
      const options = {
        // margin:       0.5,              // Custom margins
        filename:     'download.pdf',   // Output PDF name
        image:        { type: 'jpeg', quality: 0.98 }, // Image type and quality
        html2canvas:  { scale: 2, useCORS: true }, // Canvas options for quality and cross-origin images
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' } // PDF format
      };
      html2pdf().set(options).from(element).save();
    };

    const generateAndUploadPdf = async () => {
      try {
        // Convert the HTML content to a PDF blob
        const content = contentRef.current;
        const pdfBlob = await html2pdf()
          .set({
            margin: 10,
            filename: "generated.pdf",
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          })
          .from(content)
          .toPdf()
          .outputPdf("blob");
  
        // Send the PDF file to the server
        const formData = new FormData();
        formData.append("pdf", pdfBlob, "generated.pdf");
  
        const response = await questionPdfUpload(paper.id, formData)
        // const response = await axios.post(`http://localhost/questions-creator/public/api/question-paper/uploadPdf/${paper.id}`, formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // });

        if (response.status == 200) {
          alert("PDF uploaded successfully!");
          navigate('/question-paper-list')
        } else {
          alert("Failed to upload PDF.");
        }
      } catch (error) {
        console.error("Error generating or uploading PDF:", error);
        alert("An error occurred.");
      }
    };

   
    
    return (

      <div className="container"    >
      

          <div className='row' >
              <div className='col-md-2'>

                    <div>
                      {dataArray.map((item, index) => (
                        <div
                          key={item.id}
                          style={{
                            height: "1090px", // A4 page height
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            pageBreakAfter: index === dataArray.length - 1 ? "auto" : "always",
                          }}
                        >
                         
                        </div>
                      ))}
                  </div>

              </div>
              <div className='col-md-8' style={{maxWidth: '790px'}} >
                  <div className="container mt-2" ref={contentRef} style={{paddingLeft: 30, paddingRight: 30}}>
                    {/* Header Section */}

                    <p>
                    
                    </p>

                    <header className="text-center mb-4">
                    {paper.headerImage ? (
                                                  <img
                                                  src={`${paper.headerImage}`}
                                                  alt="Preview"
                                                  className="img-fluid w-80"
                                                />
                                                ) : (
                                                  ''
                                                )}
                      {/* <h2>{paper.InstituteName}</h2>
                      <h3>{paper.title}</h3> */}
                    </header>
              
                  
              
                    {/* Section B: Short Answer Questions */}
                    {templateData && templateData.sections && templateData.sections.map((section, i)=>{
                        return (
                          <section className="mb-4"  key={i}>
                            <h3>{section.section_name}</h3>
                          
                            {section.questions && section.questions.map((question, index) => {

                                return (
                                      <div className="row">
                                          <div className="col-md-10">
                                              <Wrapper id={index} questionString={JSON.stringify(question)}></Wrapper>
                                          </div>
                                          <div className="col-md-2">
                                              <p style={{float: 'right'}}>Marks: {question.marks}</p>
                                          </div>
                                      </div>
                                )

                            })}  
                          
                          </section>
                        )
                    
                    })}
                  
              
                  
                  </div>
              </div>

              <div className='col-md-2'>
                        

                       
                        
                        
              </div>

          </div>

     
  
      </div>
    );
  };
  

// Document Component
const QuestionTemplate = () => {
  const contentRef = useRef();
  const [paper, setPaper] = useState('')

 
  const { id } = useParams(); 

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await fetchQuestionTemplate(id)
        console.log('response')
        console.log(response)
  
        setPaper(JSON.stringify(response.data.data.QuestionTemplate))
      } catch (error) {
        console.error('Error fetching worksheet:', error);
      }
    };
    if(id){
      fetchQuestion();
    }
    
  }, [id]);


  
  return(
    <div>
        <div ref={contentRef}>
      
        
            <QuestionHtmlView paperString={paper} ></QuestionHtmlView>
          
          
        
      
        </div>

        
   
    </div>
  );

}

  export default QuestionTemplate;

