import React from "react"
import Navigation from "./Navigation"
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../actions/authActions';
const PrivateHeader = ()=>{
    const auth = useSelector((state) => state.auth);
    const storedToken = localStorage.getItem("token");
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const handleLogout = () => {
        // Dispatch logout action
        dispatch(logout());
        // Redirect to login page
        navigate('/login');
    };
    const handleLogin = () => {
        // Dispatch logout action
        dispatch(logout());
        // Redirect to login page
        navigate('/login');
    };

   return(
    <header className="bg-primary text-white text-center py-3">
        
        

        <div className="row"> 
                <div className="col-md-2">
                    <h4 style={{color: '#fff'}}>STUDENT GROWS</h4>
                </div>
                <div className="col-md-9">
                    <Navigation></Navigation>
                </div>
                <div className="col-md-1">
                    <nav>
                            {storedToken ? (
                                <button onClick={handleLogout} >Logout</button>
                            ) : (
                                <button onClick={handleLogin} >Login</button>
                            )}
                    </nav>
                </div>
        </div>
    </header>
   )

}

export default PrivateHeader