import React from "react"
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
const Navigation = ()=>{
        const storedToken = localStorage.getItem("token");
        //const role = localStorage.getItem("role");
        const { teacher } = useSelector((state) => state.auth);

        console.log('teacher')
        console.log(teacher)


        if(!storedToken){
            return(
                <nav className="ml-auto">
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/">Home</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/contact">Contact</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/features">Features</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/about">About</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/#">Tutorials</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/faq">Faq</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/#">Blog</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/register">Register</Link>
                </nav>
            )
        }

        //const {selectedClass} = useSelector((state) => state.classes);

        if(teacher && teacher.role == 'studentsTeacher'){
            return(
                <nav className="ml-auto">
                   
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/questions">Question List</Link> */}
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/newquestions">Question List</Link>
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-question">Create Question</Link> */}
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-newquestion">Create Question</Link>
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-paper">Create Paper</Link> */}
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-newquestion-pdf">Create Paper</Link>

                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/newquestion-view">Paper View</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/newquestion-pdf-list">Paper Pdf List</Link>
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-view">Paper View</Link> */}
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-list">Paper List</Link> */}
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-newquestion-pdf-list">Admin Paper Pdf List</Link>
                    {/* <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-question-paper-list">Admin Paper List</Link> */}
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-papers">Sample Papers</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-paper">Add Sample Paper</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-templates">Paper Templates</Link>
                    
                </nav>
            )
        }

       else if(teacher &&  teacher.role == 'kidsTeacher'){
            return(
                <nav className="ml-auto">
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheets">Worksheet Questions</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet-pdf">Create Worksheet</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-view">Worksheet View</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-list">Worksheet Pdf List</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-worksheets">Sample Worksheets</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-worksheet">Add Sample Worksheet</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-templates">Worksheet Templates</Link>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-worksheet-template">Add Worksheet Template</Link>
                </nav>
            )
       }

       else if(teacher &&  teacher.role == 'teachersAdmin'){
        return(
            <nav className="ml-auto">
                
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/questions">Question List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-question">Create Question</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-paper">Create Paper</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-view">Paper View</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-paper-list">Paper List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/sample-papers">Sample Papers</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-sample-paper">Add Sample Paper</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/add-question-template">Add Paper Template</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/question-templates">Paper Templates</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-question-paper-list">Admin Paper List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheets">Worksheet Questions</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet">Create Worksheet Question</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/create-worksheet-pdf">Create Worksheet</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-view">Worksheet View</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-list">Worksheet Pdf List</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/worksheet-pdf-view">Worksheet Pdf Creater</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/admin-worksheet-pdf-list">Admin Worksheet Pdf List</Link>
            </nav>
        )
       }    
      
       else {
        return(
            <nav className="ml-auto">
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/">Home</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/contact">Contact</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/features">Features</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/about">About</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/#">Tutorials</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/faq">Faq</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/#">Blog</Link>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} className="text-white mx-3" to="/register">Register</Link>
            </nav>
        )
       }
       
   
}
export default Navigation