import { GET_NEWQUESTIONS } from '../actions/newQuestionActions';

const initialState = {
  newQuestionList: [],
  loading: false,
  error: null,
  selectedNewQuestion: ''
};

const newQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWQUESTIONS:
      return { ...state, newQuestionList: action.payload, loading: false };
    default:
      return state;
  }
};

export default newQuestionReducer;
