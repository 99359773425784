// src/pages/Home.js
import React from 'react';


const Features = () => {
  return (
    <>
    
    
    <main className="container py-5">
        <div className="row mb-4">
            <div className="col-12 text-center">
                <h2 className="mb-3">What We Offer</h2>
                <p className="text-muted">Our platform provides a comprehensive set of tools to streamline content creation for teachers and educators.</p>
            </div>
        </div>

        <div className="row g-4">
           
            <div className="col-md-4">
                <div className="card shadow h-100">
                    <div className="card-body text-center">
                        <i className="bi bi-question-circle display-4 text-primary"></i>
                        <h5 className="card-title mt-3">Question Creator</h5>
                        <p className="card-text">Easily design and organize questions by type, topic, and difficulty level.</p>
                    </div>
                </div>
            </div>

      
            <div className="col-md-4">
                <div className="card shadow h-100">
                    <div className="card-body text-center">
                        <i className="bi bi-pencil-square display-4 text-success"></i>
                        <h5 className="card-title mt-3">Assignment Builder</h5>
                        <p className="card-text">Create assignments with text, images, and file uploads, and customize layouts with ease.</p>
                    </div>
                </div>
            </div>

      
            <div className="col-md-4">
                <div className="card shadow h-100">
                    <div className="card-body text-center">
                        <i className="bi bi-file-earmark-text display-4 text-warning"></i>
                        <h5 className="card-title mt-3">Worksheet Generator</h5>
                        <p className="card-text">Generate worksheets with a variety of formats, including multiple-choice, fill-in-the-blank, and more.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-md-6">
                <h3 className="mb-3">Why Choose Us?</h3>
                <ul className="list-group">
                    <li className="list-group-item">User-friendly interface for educators of all technical levels.</li>
                    <li className="list-group-item">Customizable templates for rapid content creation.</li>
                    <li className="list-group-item">Secure cloud storage for all your materials.</li>
                </ul>
            </div>
            <div className="col-md-6">
                <h3 className="mb-3">Additional Features</h3>
                <ul className="list-group">
                    <li className="list-group-item">Real-time collaboration with other educators.</li>
                    <li className="list-group-item">Integration with learning management systems (LMS).</li>
                    <li className="list-group-item">Comprehensive analytics to track student performance.</li>
                </ul>
            </div>
        </div>
    </main>

 
    
    
    
    </>
  );
};

export default Features;
