import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { PlusCircle, DashCircle } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { useParams } from 'react-router-dom';
import { fetchNewQuestion, uploadNewQuestionContentImage, createNewQuestion, updateNewQuestion, deleteNewQuestion } from '../../api';

const CreateNewQuestion = () => {
  const [newQuestion, setNewQuestion] = useState({ title: '', content: [] });
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();

  const {selectedClass} = useSelector((state) => state.classes);
  const {selectedSubject} = useSelector((state) => state.subjects);
  const {selectedTopic, selectedTopicId} = useSelector((state) => state.topics);
  const {selectedSubTopic, selectedSubTopicId} = useSelector((state) => state.subtopics);

  const { id } = useParams(); 

  let newQuestionId = id;

  // Fetch the newQuestion data on component load
  useEffect(() => {
    const getNewQuestion = async () => {
      try {
        const response = await fetchNewQuestion(newQuestionId)
        
        const newQuestion = {
          'title' : response.data.data.newQuestion.title,
          'content': response.data.data.content
        }

        setNewQuestion(newQuestion);
      } catch (error) {
        console.error('Error fetching newQuestion:', error);
      }
    };
    if(newQuestionId){
      getNewQuestion();
    }
    
  }, [newQuestionId]);

  const addContentSection = () => {
    setNewQuestion({
      ...newQuestion,
      content: [...newQuestion.content, { id: Date.now(), textContent: '', imageContent: null }],
    });
  };

  const updateContentSection = (id, field, value) => {
    setNewQuestion({
      ...newQuestion,
      content: newQuestion.content.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      ),
    });
  };

  const deleteContentSection = (id) => {
    setNewQuestion({
      ...newQuestion,
      content: newQuestion.content.filter((section) => section.id !== id),
    });
  };

  const uploadImage = async (id, file) => {

    console.log('hi')
    console.log(file)
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await uploadNewQuestionContentImage(formData);
      console.log(response)
      updateContentSection(id, 'imageContent', response.data.data.image_url);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const saveNewQuestion = async () => {
    try {
      console.log(newQuestion)
      newQuestion.subtopic_id = selectedSubTopicId;
      newQuestion.topic_id = selectedTopicId;
      console.log('newQuestion')
      console.log(newQuestion)
      const response = await createNewQuestion(newQuestion)
      alert('newQuestion saved successfully');
      console.log(response)
      navigate('/newquestions')
      //setPreview(response.data.data);
    } catch (error) {
      console.error('Error saving newQuestion:', error);
    }
  };

  const handleUpdateNewQuestion = async () => {
    console.log(newQuestion)
    try {
      const response = await updateNewQuestion(newQuestionId, newQuestion);
      alert('newQuestion saved successfully');
      console.log(response)
      navigate('/newquestions')
      //setPreview(response.data.data);
    } catch (error) {
      console.error('Error saving newquestion:', error);
    }
  };

  const handleDeleteNewQuestion = async () => {
    if (!window.confirm('Are you sure you want to delete this newQuestion?')) return;
    console.log(newQuestion)
    try {
      const response = await deleteNewQuestion(newQuestionId, newQuestion);
      alert('NewQuestion saved successfully');
      console.log(response)
      navigate('/newquestions')
      //setPreview(response.data.data);
    } catch (error) {
      console.error('Error saving newquestion:', error);
    }
  };

  return (
    <div className="wrapper">
    
      <div className="row">
        <Sidebar></Sidebar>
        <div className="container mt-4 col-md-9 col-lg-10 ">
          <div className="class-info">
              <p><strong>Class:</strong> {selectedClass}</p>
              <p><strong>Subject:</strong> {selectedSubject}</p>
              <p><strong>Topic:</strong> {selectedTopic}</p>
              <p><strong>SubTopic:</strong> {selectedSubTopic}</p>
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">NewQuestion Title</label>
            <textarea 
              type="textarea"
              rows="2"
              className="form-control"
              id="title"
              placeholder="Enter newQuestion title"
              value={newQuestion.title}
              onChange={(e) => setNewQuestion({ ...newQuestion, title: e.target.value })}
            />
          </div>
          <button className="btn btn-primary mb-3" onClick={addContentSection}>
            <PlusCircle /> Add Content Section
          </button>
          <div>
            {newQuestion.content.map((section) => (
              <div key={section.id} className="card mb-3">
                <div className="card-body">
                  <textarea
                    className="form-control mb-2"
                    placeholder="Text Content"
                    value={section.textContent}
                    onChange={(e) => updateContentSection(section.id, 'textContent', e.target.value)}
                  />
                  <input
                    type="file"
                    className="form-control mb-2"
                    onChange={(e) => uploadImage(section.id, e.target.files[0])}
                  />
                  {/* Image Position Dropdown */}
                  <select
                    className="form-select mb-2"
                    value={section.position}
                    onChange={(e) => updateContentSection(section.id, 'position', e.target.value)}
                  >
                    <option value="Right">Right</option>
                    <option value="Left">Left</option>
                    <option value="Bottom">Bottom</option>
                  </select>

                  <button className="btn btn-danger" onClick={() => deleteContentSection(section.id)}>
                    <DashCircle /> Delete Section
                  </button>
                </div>
              </div>
            ))}
          </div>

          {newQuestionId ? (
            <div>
              <button className="btn btn-success" onClick={handleUpdateNewQuestion}>Update NewQuestion</button>
              <button className="btn btn-danger" onClick={handleDeleteNewQuestion}>Delete NewQuestion</button>
            </div>
            
          ) : (
            <button className="btn btn-success" onClick={saveNewQuestion}>Save NewQuestion</button>
          )}
          

          {preview && (
            <div className="mt-5">
              <h3>NewQuestion Preview</h3>
              <h4>{preview.title}</h4>
              {/* {preview.content.map((section, index) => (
                <div key={index} className="mb-3">
                  <p>{section.textContent}</p>
                  {section.imageContent && <img src={section.imageContent} alt="Content" style={{ maxWidth: '100%' }} />}
                </div>
              ))} */}
            </div>
          )}
        </div>
      
      </div>
  </div>
  );
};

export default CreateNewQuestion;
