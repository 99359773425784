// src/pages/Home.js
import React from 'react';


const About = () => {
  return (
    <>
    
    
    <section class="py-5">
    <div class="container">
      <div class="row align-items-center">
        {/* <div class="col-lg-6">
          <img src="https://via.placeholder.com/500x300" alt="About Us" class="img-fluid rounded"/>
        </div> */}
        <div class="col-lg-10">
          <h2 class="mb-4">Who We Are</h2>
          <p>we are passionate about simplifying the process of educational content creation. Our platform
            is designed for teachers, tutors, and institutions to efficiently generate high-quality questions, engaging
            assignments, and comprehensive worksheets for all grade levels and subjects.</p>
          <p>With intuitive tools, customizable templates, we aim to save you time and
            enhance the learning experience for your students.</p>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-light py-5">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-6 mb-4">
          <h3>Our Mission</h3>
          <p>To empower educators with the tools they need to create personalized and impactful educational resources,
            enabling every student to thrive.</p>
        </div>
        <div class="col-lg-6 mb-4">
          <h3>Our Vision</h3>
          <p>To be the leading platform for education content creation, fostering innovation in teaching and learning
            worldwide.</p>
        </div>
      </div>
    </div>
  </section>


  <section class="py-5 text-black text-center">
    <div class="container">
      <h3>Start Creating Today</h3>
      <p>Join thousands of educators using Student Grows to make learning more effective and engaging.</p>
      <a href="#" class="btn btn-light btn-lg mt-3">Get Started</a>
    </div>
  </section>

    
    </>
  );
};

export default About;
