import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useRef } from 'react';
import {  UPDATE_PAPER_SECTION } from '../../actions/newQuestionActions';
import { UPDATE_PAPER_SECTION_NEWQUESTION } from '../../actions/paperActions';

import 'bootstrap-icons/font/bootstrap-icons.css';

import Papertitle from '../../components/PaperTitle'
import CreateSection from '../../components/CreateSection'
import PaperSubTitle from '../../components/PaperSubTitle';
import EditSection from '../../components/EditSection';
import EdiNewQuestion from '../../components/EditNewQuestion';
import Sidebar from '../../components/Sidebar';

import '../../styles/CreatePaper.css'
import "../../styles/question-list.css"
import { redirect, useNavigate  } from 'react-router-dom';

const Wrapper = ({ id, newQuestion_text }) => {
    return <p>{id+1}. <span className='mt-4 2rem' dangerouslySetInnerHTML={{ __html: newQuestion_text }} ></span></p>;
};

const CreateNewQuestionPdf = () => {
  
    const [paperPreview, setPaperPreview] = useState(false);
    const [isNewQuestionScreenFullWidth, setIsNewQuestionScreenFullWidth] = useState(false);

    const [sections, setSections] = useState([]);
    const [isEditSection, setIsEditSection] = useState(false);
    const [editSectionId, setEditSectionId] = useState(null);
    const [editSectionName, setEditSectionName] = useState(null);

    const [isEditNewQuestion, setIsEditNewQuestion] = useState(false);
    const [editNewQuestionId, setEditNewQuestionId] = useState(null);
    const [editNewQuestionText, setEditNewQuestionText] = useState(null);
    const [editMarks, setEditMarks] = useState(null);

    const [selectedNewQuestions, setSelectedNewQuestions] = useState([]);
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const {selectedClass} = useSelector((state) => state.classes);
    const {selectedSubject} = useSelector((state) => state.subjects);
    const {selectedTopic} = useSelector((state) => state.topics);
    const {selectedSubTopic} = useSelector((state) => state.subtopics);
    const {newQuestionList}  = useSelector((state) => state.newQuestions);
    const {selectedPaper} = useSelector((state) => state.papers);

    const parser = new DOMParser();

    const dispatch = useDispatch();
 
  

    const handlePaperPreview = ()=>{
      if(paperPreview){
        setPaperPreview(false)
      }
      else{
        setPaperPreview(true)
      }
    }
   
    const handleNewQuestionScreenWidth = ()=>{
      if(isNewQuestionScreenFullWidth){
        setIsNewQuestionScreenFullWidth(false)
      }
      else{
        setIsNewQuestionScreenFullWidth(true)
      }
    }
   
    const enableEditSection = (sectionId, sectionName)=>{
        setEditSectionId(sectionId)
        setEditSectionName(sectionName)
        setIsEditSection(true)
    }

    const disableEditSection = ()=>{
      setEditSectionId(null)
      setEditSectionName(null)
      setIsEditSection(false)
    }

    const enableEditNewQuestion = (sectionId, newQuestionId, newQuestionText, marks)=>{
      setEditSectionId(sectionId)
      setEditNewQuestionId(newQuestionId)
      setEditNewQuestionText(newQuestionText)
      setEditMarks(marks)
      setIsEditNewQuestion(true)
    }

    const disableEditNewQuestion = ()=>{
      setEditSectionId(null)
      setEditNewQuestionId(null)
      setEditNewQuestionText(null)
      setEditMarks(null)
      setIsEditNewQuestion(false)
    }

    const deleteSection = (sectionId)=>{
      let updatedSections = selectedPaper.sections.filter((item)=>{
            if(item.id != sectionId){
              return item;
            }  
      })
     
      dispatch({
        'type': UPDATE_PAPER_SECTION,
        'payload': [...updatedSections]
      })
      
  }

 

    

  const handleSectionChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const sectionId = selectedOption.getAttribute('data-id');

  
    console.log(sectionId)
    setSelectedSectionId(sectionId)
  };

  // Function to handle when a NewQuestion is selected or deselected
  const handleSelectNewQuestion = (newQuestion) => {
    setSelectedNewQuestions((prevSelected) => {
      // Check if the NewQuestion is already selected
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.id === newQuestion.id
      );

      if (isAlreadySelected) {
        // If it's already selected, remove it from the array
        return prevSelected.filter((selected) => selected.id !== newQuestion.id);
      } else {
        // If it's not selected, add it to the array
        return [...prevSelected, newQuestion];
      }
    });
  };


  // Function to move selected newQuestions to a specific section
  const handleMoveToSection = () => {

    const updatedSections = selectedPaper.sections.map((section) => {
        
          if (section.id == selectedSectionId) {
            console.log(selectedNewQuestions)
            console.log(section)
            const filterNewQuestions =  selectedNewQuestions
            .filter((item1) => {
                  let newQuestionExists = section.newQuestions.some(item2 => item1.id == item2.id)
                  if(!newQuestionExists){
                    return item1
                  }
              });
            return {
              ...section,
              newQuestions: [
                ...section.newQuestions,
                ...filterNewQuestions,
              ],
            };
          }
          return section;

        })

    // setSections(updatedSections);

    dispatch({
      'type': UPDATE_PAPER_SECTION,
      'payload': updatedSections
    })


    // Clear the temporary selected newQuestions after moving
    setSelectedNewQuestions([]);
  };


  // Function to handle deleting a NewQuestion from a section
  
  const handleDeleteNewQuestion = (sectionId, newQuestionId)=>{
    let updatedSections = selectedPaper.sections.map((section)=>{
          if(section.id == sectionId){
            let newQuestions = section.newQuestions.filter((newQuestion)=>{
               if(newQuestion.id != newQuestionId){
                  return newQuestion
               }
            })
            section.newQuestions = newQuestions
          }  
          return section 
    })
   
    dispatch({
      'type': UPDATE_PAPER_SECTION_NEWQUESTION,
      'payload': [...updatedSections]
    })
    
}

  return (
    <div className="wrapper">
    
      <div className="row">
        <Sidebar></Sidebar>
        <div className="col-md-9 col-lg-10 content">
        <div className="row">

          <div className={ isNewQuestionScreenFullWidth ? 'col-md-12' : 'col-md-6'} style={ paperPreview ? { display: 'none' } : { display: 'block'} }>
            <div className="content-section">
              <div className="container">
              
                <div className="row">
                        <div className="col-md-8">
                          <h4>Exam Questions</h4>
                        </div>
                        <div className="col-md-4">
                              <button className="btn btn-primary btn-sm" onClick={handleNewQuestionScreenWidth}> {isNewQuestionScreenFullWidth ? 'Exit Full Screen' : 'Full Screen'} </button>
                        </div>
                  </div>

                <div className="class-info">
                    <p><span><strong>Class:</strong>  {selectedClass}  </span>
                    <span><strong>Subject:</strong>  {selectedSubject}  </span></p>
                    <p><span><strong>Topic:</strong>  {selectedTopic}  </span>
                    <span><strong>SubTopic:</strong>  {selectedSubTopic}</span></p>
                </div>

        
                <div className="row mb-4 mt-2">
                  <div className="col-md-8">
                    <select className="form-select" onChange={handleSectionChange}>
                        <option>Select Section</option>
                        {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>( 
                            <option key={Item.id}  data-id={Item.id}>{Item.section_name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <button className="btn btn-primary btn-sm" onClick={handleMoveToSection}>Move NewQuestion</button>
                  </div>
                </div>
        
              

                
                {newQuestionList && newQuestionList.map((newQuestion, questionNo)=>(

                      <div className="question" key={newQuestion.id}>
                                {newQuestion.contents.map((content, i)=>{
                                 
                                      if(content.textContent == 'Question'){
                                        return(
                                          <div className="row">
                                            <div className="col-md-12">
                                                  <div className="image-text-container">
                                                      <p>{questionNo+1}.</p>
                                                      <div className="image-wrapper">
                                                      
                                                          {content.imageContent ? (
                                                                <img
                                                                src={`${content.imageContent}`}
                                                                alt="Preview"
                                                                className="img-fluid w-80"
                                                              />
                                                              ) : (
                                                                ''
                                                              )}
                                                      </div>
                                                  </div>
                                              
                                            </div>
                                        </div>
                                        )
                                      }
                                    
                                })}  


                                <div className="row">
                                      <div className="col-md-9">
                                      {/* <p><strong>{i + 1}. {newQuestion.title}</strong> </p> */}
                                      </div>
                                      <div className="col-md-3">
                                          <p style={{marginBottom: 0}}><strong>Marks:</strong> {newQuestion.marks}</p>
                                          <label className="form-check-label" for="q4">Select</label>
                                          <input type="checkbox" className="form-check-input" value={newQuestion.id}
                                              checked={selectedNewQuestions.some(
                                                  (selected) => selected.id === newQuestion.id
                                              )}
                                              onChange={() => handleSelectNewQuestion(newQuestion)}>

                                          </input>
                                      </div>
                                </div>
                
                          </div>

                ))}

            </div>
            </div>
          </div>

        
          <div className={ paperPreview ? 'col-md-12' : 'col-md-6'} style={ isNewQuestionScreenFullWidth ? { display: 'none' } : { display: 'block'} }>
            <div className="content-section">
              <div className="container">

                  <div className="row mb-2">
                        <div className="col-md-9">
                          <h4>Exam Paper </h4>
                        </div>
                        <div className="col-md-3">
                              <button className="btn btn-primary btn-sm" onClick={handlePaperPreview}> {paperPreview ? 'Exit Full Screen' : 'Full Screen'} </button>
                        </div>
                  </div>
              
              

                    <Papertitle></Papertitle>

                    <PaperSubTitle></PaperSubTitle>
                
                    <CreateSection></CreateSection>
                    
                    {isEditSection ? (<EditSection sectionId={editSectionId} editSectionName={editSectionName} disableEditSection={disableEditSection}></EditSection>) : (<div></div>)}

                    {isEditNewQuestion ? (<EdiNewQuestion 
                      sectionId={editSectionId} 
                      newQuestionId={editNewQuestionId} 
                      editNewQuestionText={editNewQuestionText} 
                      editMarks={editMarks} 
                      disableEditNewQuestion={disableEditNewQuestion}
                    ></EdiNewQuestion>) : (<div></div>)}

                

              {selectedPaper.sections &&  selectedPaper.sections.map((Item, i)=>(
                        <div className="mb-4 question" key={Item.id}>

                          <div className="row mb-4">
                                  <div className="col-md-10">
                                      <div className="d-flex justify-content-start">
                                            <p className='h4'>{Item.section_name}</p>
                                      </div>
                                  </div>
                                  <div className="col-md-2">
                                      <div className="d-flex justify-content-end ">
                                      <i className="fas fa-trash"></i> {/* Example icon */}
                                          <button className="btn float-right" onClick={()=>{enableEditSection(Item.id, Item.section_name)}}><i className="bi bi-pencil-square"></i></button>
                                          <button className="btn float-right" onClick={()=>{deleteSection(Item.id)}}><i className="bi bi-trash"></i> </button>
                                      </div>
                                  </div>
                          </div>

                          
                                            

                        {Item.newQuestions && Item.newQuestions.map((newQuestion, questionNo)=>(
                              <div className="row mb-2">

                                

                                {newQuestion.contents.map((content, i)=>{
                                      if(content.textContent == 'Question'){
                                        return(
                                          <div className="row">
                                            <div className="col-md-12">
                                                  <div className="image-text-container">
                                                      <p>{questionNo+1}.</p>
                                                      <div className="image-wrapper">
                                                      
                                                          {content.imageContent ? (
                                                                <img
                                                                src={`${content.imageContent}`}
                                                                alt="Preview"
                                                                className="img-fluid w-80"
                                                              />
                                                              ) : (
                                                                ''
                                                              )}
                                                      </div>
                                                  </div>
                                              
                                            </div>
                                        </div>
                                        )
                                      }
                              
                                  })}  


                                <div className="col-md-9">
                                    {/* <p><strong>{i + 1}. {newQuestion.title}</strong> </p> */}
                                </div>
                                <div className="col-md-3 text-end">
                                    <p style={{marginBottom: 0}}><strong>Marks:</strong> {newQuestion.marks}</p>
                                    <button className="btn" onClick={()=>{enableEditNewQuestion(Item.id, newQuestion.id)}} ><i className="bi bi-pencil-square"></i></button>
                                    <button className="btn" onClick={() =>
                                                handleDeleteNewQuestion(Item.id, newQuestion.id)
                                            }><i className="bi bi-trash"></i> </button>
                                </div>
                                


                            </div>
                        ))} 
                
                        
                    </div>
              ))}
                
          
                
        
          
                
            </div>
            </div>
          </div>
        </div>
        </div>
      
      </div>

  </div>
  );
};

export default CreateNewQuestionPdf;
