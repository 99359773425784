import React from 'react';


const Faq = () => {
  return (
    <>
 
  <header className="text-black text-center py-5">
    <div className="container">
      <h1>Frequently Asked Questions</h1>
      <p className="lead">Find answers to the most commonly asked questions about Student Grows.</p>
    </div>
  </header>

 
  <section className="py-5">
    <div className="container">
      <h2 className="text-center mb-4">Your Questions, Answered</h2>
      <div className="accordion" id="faqAccordion">
       
        <div className="accordion-item">
          <h2 className="accordion-header" id="faqHeading1">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse1"
              aria-expanded="true" aria-controls="faqCollapse1">
              What is Student Grows?
            </button>
          </h2>
          <div id="faqCollapse1" className="accordion-collapse collapse show" aria-labelledby="faqHeading1"
            data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Student Grows is an online platform that allows educators to easily create questions, assignments, and
              worksheets tailored to their specific needs. It includes customizable templates, easy-to-use tools, and
              seamless sharing options.
            </div>
          </div>
        </div>
       
        <div className="accordion-item">
          <h2 className="accordion-header" id="faqHeading2">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse2"
              aria-expanded="false" aria-controls="faqCollapse2">
              Is Student Grows free to use?
            </button>
          </h2>
          <div id="faqCollapse2" className="accordion-collapse collapse" aria-labelledby="faqHeading2"
            data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Student Grows offers both free and premium plans. The free plan includes basic features, while the premium
              plan unlocks advanced tools, extra templates, and priority support.
            </div>
          </div>
        </div>
       
        <div className="accordion-item">
          <h2 className="accordion-header" id="faqHeading3">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse3"
              aria-expanded="false" aria-controls="faqCollapse3">
              Can I upload my own content to Student Grows?
            </button>
          </h2>
          <div id="faqCollapse3" className="accordion-collapse collapse" aria-labelledby="faqHeading3"
            data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Yes! Student Grows allows you to upload your own text, images, and files to customize your questions,
              assignments, and worksheets.
            </div>
          </div>
        </div>
       
        <div className="accordion-item">
          <h2 className="accordion-header" id="faqHeading4">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse4"
              aria-expanded="false" aria-controls="faqCollapse4">
              How can I share my created materials with students?
            </button>
          </h2>
          <div id="faqCollapse4" className="accordion-collapse collapse" aria-labelledby="faqHeading4"
            data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Student Grows allows you to download your materials in PDF format or share them via email and online
              classNameroom platforms like Google classNameroom or Microsoft Teams.
            </div>
          </div>
        </div>
       
        <div className="accordion-item">
          <h2 className="accordion-header" id="faqHeading5">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse5"
              aria-expanded="false" aria-controls="faqCollapse5">
              Is there a limit to how many worksheets I can create?
            </button>
          </h2>
          <div id="faqCollapse5" className="accordion-collapse collapse" aria-labelledby="faqHeading5"
            data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Free plan users have a monthly limit on creations, but premium users can create an unlimited number of
              worksheets and assignments.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  
    </>
  );
};

export default Faq;
