import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchTeacher } from './api'

import Home from './pages/Home';
import PrivateHeader from './components/PrivateHeader';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Features from './pages/Features';
import About from './pages/About';
import Faq from './pages/Faq';
import Tutorial from './pages/Tutorial';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Register from './pages/Register';
import TeacherProfile from './pages/TeacherProfile';
import PrivateRoute from './components/PrivateRoute';

import Worksheets from './pages/worksheetPages/Worksheets';
import CreateWorksheet from './pages/worksheetPages/CreateWorksheet';
import CreateWorksheetPdf from './pages/worksheetPages/CreateWorksheetPdf';
import WorksheetPdfPreview from './pages/worksheetPages/WorksheetPdfPreview';
import WorksheetView from './pages/worksheetPages/WorksheetView';
import WorksheetPdfList from './pages/worksheetPages/WorksheetPdfList';
import SampleWorksheets from './pages/sampleWorksheets/SampleWorksheets';
import CreateSampleWorksheet from './pages/sampleWorksheets/CreateSampleWorksheet';
import AdminWorksheetPdfList from './pages/worksheetPages/AdminWorksheetPdfList';
import AddWorksheetTemplate from './pages/worksheetTemplates/AddWorksheetTemplate';
import WorksheetTemplates from './pages/worksheetTemplates/WorksheetTemplates';

import Questions from './pages/questionPages/Questions';
import CreatePaper from './pages/questionPages/CreatePaper';
import PdfPreview from './pages/questionPages/PdfPreview';
import QuestionPaperView from './pages/questionPages/QuestionPaperView';
import QuestionPaperList from './pages/questionPages/QuestionPaperList';
import AdminQuestionPaperList from './pages/questionPages/AdminQuestionPaperList';
import QuestionForm from './pages/questionPages/QuestionForm';
import SamplePapers from './pages/samplePapers/SamplePapers';
import CreateSamplePaper from './pages/samplePapers/CreateSamplePaper';
import AddQuestionTemplate from './pages/questionTemplates/AddQuestionTemplate';
import QuestionTemplates from './pages/questionTemplates/QuestionTemplates';
import QuestionTemplate from './pages/questionTemplates/QuestionTemplate';

import CreateNewQuestion from './pages/newquestionPages/CreateNewQuestion';
import NewQuestions from './pages/newquestionPages/NewQuestions';
import CreateNewQuestionPdf from './pages/newquestionPages/CreateNewQuestionPdf';
import NewQuestionView from './pages/newquestionPages/NewQuestionView';
import NewQuestionPdfPreview from './pages/newquestionPages/NewQuestionPdfPreview';
import NewQuestionPdfList from './pages/newquestionPages/NewQuestionPdfList';
import AdminNewQuestionPdfList from './pages/newquestionPages/AdminNewQuestionPdfList';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const findTeacher = async ()=>{
      const storedToken = localStorage.getItem("token");
      if(storedToken){
        try{
          let data = {
            token: storedToken
          }
          const responce = await fetchTeacher(data)  
          console.log('teacher responce')
          console.log(responce)
          const loginData = {
            'token': responce.data.data.Teacher.token,
            'teacher': {
                'role': responce.data.data.Teacher.role
            } 
          }
          dispatch({
              type: 'LOGIN_SUCCESS',
              payload: loginData, 
          });

        }
        catch(error){
          console.log(error)
        }
          
      }

      else{

        dispatch({
            type: 'LOGOUT',
            payload: '', 
        });

      }
    }
   
    findTeacher()
    
  }, [dispatch]);


  return (

    <Router >
      <div>
        {/* Header */}
        
        <PrivateHeader></PrivateHeader>
        {/* Main content */}
      
          <Routes>

            <Route path="/" element={<Home />} />

            <Route path="/login" element={<Login />} />

            <Route path="/signup" element={<Signup />} />

            <Route path="/features" element={<Features />} />

            <Route path="/about" element={<About />} />

            <Route path="/contact" element={<Contact />} />

            <Route path="/faq" element={<Faq />} />

            <Route path="/tutorial" element={<Tutorial />} />

            <Route path="/blog" element={<Blog />} />

            <Route path="/register" element={<Register />} />

            <Route path="/profile" element={<PrivateRoute><TeacherProfile /></PrivateRoute>}/>

            <Route path="/questions" element={<PrivateRoute><Questions /></PrivateRoute>}/>

            <Route path="/create-question" element={<PrivateRoute><QuestionForm /></PrivateRoute>}/>

            <Route path="/edit-question/:id" element={<PrivateRoute><QuestionForm /></PrivateRoute>}/>

            <Route path="/create-paper" element={<PrivateRoute><CreatePaper /></PrivateRoute>}/>

            <Route path="/question-paper-view" element={<PrivateRoute><QuestionPaperView /></PrivateRoute>}/>

            <Route path="/question-paper-list" element={<PrivateRoute><QuestionPaperList /></PrivateRoute>}/>
           
            <Route path="/create-paper-pdf/:id" element={<PrivateRoute><PdfPreview /></PrivateRoute>}/>

            <Route path="/sample-papers" element={<PrivateRoute><SamplePapers /></PrivateRoute>}/>

            <Route path="/add-sample-paper" element={<PrivateRoute><CreateSamplePaper /></PrivateRoute>}/>

            <Route path="/edit-sample-paper/:id" element={<PrivateRoute><CreateSamplePaper /></PrivateRoute>}/>

            <Route path="/admin-question-paper-list" element={<PrivateRoute><AdminQuestionPaperList /></PrivateRoute>}/>

            <Route path="/worksheets" element={<PrivateRoute><Worksheets /></PrivateRoute>}/>
            
            <Route path="/create-worksheet" element={<PrivateRoute><CreateWorksheet /></PrivateRoute>}/>

            <Route path="/edit-worksheet/:id" element={<PrivateRoute><CreateWorksheet /></PrivateRoute>}/>

            <Route path="/create-worksheet-pdf" element={<PrivateRoute><CreateWorksheetPdf /></PrivateRoute>}/>

            <Route path="/worksheet-view" element={<PrivateRoute><WorksheetView /></PrivateRoute>}/>

            <Route path="/worksheet-pdf-view/:id" element={<PrivateRoute><WorksheetPdfPreview /></PrivateRoute>}/>

            <Route path="/worksheet-pdf-list" element={<PrivateRoute><WorksheetPdfList /></PrivateRoute>}/>

            <Route path="/sample-worksheets" element={<PrivateRoute><SampleWorksheets /></PrivateRoute>}/>

            <Route path="/add-sample-worksheet" element={<PrivateRoute><CreateSampleWorksheet /></PrivateRoute>}/>

            <Route path="/edit-sample-worksheet/:id" element={<PrivateRoute><CreateSampleWorksheet /></PrivateRoute>}/>
        
            <Route path="/admin-worksheet-pdf-list" element={<PrivateRoute><AdminWorksheetPdfList /></PrivateRoute>}/>

            <Route path="/worksheet-templates" element={<PrivateRoute><WorksheetTemplates /></PrivateRoute>}/>

            <Route path="/add-worksheet-template" element={<PrivateRoute><AddWorksheetTemplate /></PrivateRoute>}/>

            <Route path="/edit-worksheet-template/:id" element={<PrivateRoute><AddWorksheetTemplate /></PrivateRoute>}/>

            <Route path="/question-templates" element={<PrivateRoute><QuestionTemplates /></PrivateRoute>}/>

            <Route path="/add-question-template" element={<PrivateRoute><AddQuestionTemplate /></PrivateRoute>}/>

            <Route path="/edit-question-template/:id" element={<PrivateRoute><AddQuestionTemplate /></PrivateRoute>}/>

            <Route path="/question-template/:id" element={<PrivateRoute><QuestionTemplate /></PrivateRoute>}/>

            <Route path="/newquestions" element={<PrivateRoute><NewQuestions /></PrivateRoute>}/>
            
            <Route path="/create-newquestion" element={<PrivateRoute><CreateNewQuestion /></PrivateRoute>}/>

            <Route path="/edit-newquestion/:id" element={<PrivateRoute><CreateNewQuestion /></PrivateRoute>}/>

            <Route path="/create-newquestion-pdf" element={<PrivateRoute><CreateNewQuestionPdf /></PrivateRoute>}/>

            <Route path="/newquestion-view" element={<PrivateRoute><NewQuestionView /></PrivateRoute>}/>

            <Route path="/newquestion-pdf-view/:id" element={<PrivateRoute><NewQuestionPdfPreview /></PrivateRoute>}/>

            <Route path="/newquestion-pdf-list" element={<PrivateRoute><NewQuestionPdfList /></PrivateRoute>}/>

            <Route path="/admin-newquestion-pdf-list" element={<PrivateRoute><AdminNewQuestionPdfList /></PrivateRoute>}/>
          </Routes>
     

        {/* Footer */}
        <footer className="bg-primary text-white text-center py-3">
          &copy; 2024 STUDENT GROWS All Rights Reserved
        </footer>
      </div>
    </Router>

  );
}

export default App;
