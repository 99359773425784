import { GET_SUBTOPICS, SELECT_SUBTOPIC, SELECT_SUBTOPIC_ID, RESET_SUBTOPICS } from '../actions/subTopicActions';

const initialState = {
  subTopicList: [],
  loading: false,
  error: null,
  selectedSubTopic: '',
  selectedSubTopicId: 0
};

const subTopicReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBTOPICS:
      return { ...state, subTopicList: action.payload, loading: false };
    case SELECT_SUBTOPIC:
      return { ...state, selectedSubTopic: action.payload, loading: false }; 
    case SELECT_SUBTOPIC_ID:
      return { ...state, selectedSubTopicId: action.payload, loading: false };    
    case RESET_SUBTOPICS:
      return { ...state, subTopicList: action.payload, loading: false };
    default:
      return state;
  }
};

export default subTopicReducer;
