import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import SidebarPdf from "../../components/SidebarPdf";
import { fetchNewQuestionsPdf } from '../../api';

import { useNavigate } from 'react-router-dom'

const AdminNewQuestionPdfList = () => {
  const [newQuestions, setNewQuestions] = useState([]);
  const {selectedSubTopicId} = useSelector((state) => state.subtopics); 
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const {selectedTopic} = useSelector((state) => state.topics);
  const {selectedSubTopic} = useSelector((state) => state.subtopics);

  const navigate = useNavigate();

  // Fetch newQuestions from API on load
  useEffect(() => {

    const getNewQuestionPapers = async()=>{
      if(selectedClassId != 0 && selectedSubjectId != 0){
        try{
          const response = await fetchNewQuestionsPdf(selectedClassId, selectedSubjectId)
          console.log('response')
          console.log(response)
          setNewQuestions(response.data.data)  
        }
        catch(error){
          console.log(error)
        }
      }
    }

    getNewQuestionPapers()
    
  }, [selectedClassId, selectedSubjectId]);

  // Delete a NewQuestion
  const deleteNewQuestion = (id) => {
    axios
      .post(`http://localhost/newquestions-creator/public/api/newquestion-paper/delete/${id}`, {},  {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
      })
      .then(() => {
        setNewQuestions(newQuestions.filter((newQuestion) => newQuestion.id !== id));
      })
      .catch((error) => console.error("Error deleting newQuestion:", error));
  };

  return (
    <div className="wrapper">
    
      <div className="row">
          <SidebarPdf></SidebarPdf>
          <div className="container mt-4 col-md-9 col-lg-10">
            <h3>NewQuestion Pdf List</h3>

            <div className="class-info">
                <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
                <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
            </div>


            {/* NewQuestion Table */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Status</th>
                  <th>Download Count</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {newQuestions.map((newQuestion, index) => (
                  <tr key={newQuestion.id}>
                    <td>{index + 1}</td>
                    <td>{newQuestion.title}</td>
                    <td>{newQuestion.subtitle}</td>
                    <td>{newQuestion.status}</td>
                    <td>{newQuestion.downloadCount}</td>
                    <td>
                      <Button
                       style={{marginRight: 10}}
                        variant="primary"
                        size="sm"
                        onClick={() => { navigate(`/newquestion-pdf-view/${newQuestion.id}`);}}
                      >
                        Create Pdf
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteNewQuestion(newQuestion.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
      </div>
    </div>
  );
};

export default AdminNewQuestionPdfList;
