import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../actions/authActions';
import axios from 'axios';
import "../styles/Login.css"
import { useNavigate, Link } from 'react-router-dom';
import { loginTeacher } from '../api';



const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try{
        const responce = await loginTeacher(phone, password)
        console.log('login response');
        console.log(responce)

        if(responce.status == 401){
            setError('Invalid Username or password')
        }
        else{
            localStorage.setItem('token', responce.data.data.token);
        
            const loginData = {
                'token': responce.data.data.token,
                'teacher': {
                    'role': responce.data.data.role
                }
            }
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: loginData,
            });
        
            console.log('loging.........')
            if(responce.data.data.role == 'kidsTeacher'){
                navigate('/worksheets');
            }
            else{
                navigate('/newquestions');
            }

        }
  
        
        

    }
    catch(error){
        console.log(error)
    }

   
  };


  return(
  
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-6 login-container" style={{marginTop:10}}>
                <div className="login-header">
                    <h2>Teacher Login</h2>
                    <p>Login to access your account and manage questions.</p>
                </div>

                <form>
                   
                    <div className="mb-3">
                        <label for="username" className="form-label">Username</label>
                        <input type="text" className="form-control" onChange={(e) => setPhone(e.target.value)} value={phone} placeholder="Enter phone" required/>
                    </div>

                   
                    <div className="mb-3">
                        <label for="password" className="form-label">Password</label>
                        <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter your password" required/>
                    </div>

                   
                    <div className="mb-3 text-end">
                        <a href="#" className="small">Forgot Password?</a>
                    </div>

                    
                    <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">Login</button>
                    {error != '' ? ( 
                        <p style={{color:'red'}}>{error}</p>
                    ) : ('')}
                   
                </form>

               
                <div className="login-footer mt-3">
                    <p>Don't have an account? <a href="#">Sign up</a></p>
                </div>
            </div>
        </div>
    </div>

  )
};

export default Login;
