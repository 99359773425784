import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table } from 'react-bootstrap';
import SidebarPdf from "../../components/SidebarPdf";
import { useSelector, useDispatch } from 'react-redux';
import { createQuestionTemplate, fetchQuestionTemplate, updateQuestionTemplate } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';

const AddQuestionTemplate = () => {
 
  const navigate = useNavigate();
  const { id } = useParams(); 
  let questionTemplateId = id;
  const {selectedClass, selectedClassId} = useSelector((state) => state.classes);
  const {selectedSubject, selectedSubjectId} = useSelector((state) => state.subjects);
  const [formData, setFormData] = useState({
    'id': null,
    'institute_id': '',
    'InstituteName': '',
    'class_id': '',
    'className': '',
    'subject_id': '',
    'templateData': '',
    'title': '',
    'pdf': null
  });


  // Fetch sample questions
  useEffect(() => {
    const fetchSample = async () => {
      try {
        const response = await fetchQuestionTemplate(questionTemplateId)
        setFormData({...response.data.data.questionTemplate})
        
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };
    if(questionTemplateId){
      fetchSample();
    }
    
  }, [questionTemplateId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, pdf: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.class_id = selectedClassId;
    formData.className = selectedClass;
    formData.subject_id = selectedSubjectId;
    formData.subjectName = selectedSubject;
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await createQuestionTemplate(data)
      alert('Sample Question added successfully!');
      navigate('/question-templates')
      
    } catch (error) {
      console.error('Error saving sample question:', error);
      alert('Failed to save sample question.');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await updateQuestionTemplate(formData.id, data)
      alert('Sample Question added successfully!');
      navigate('/sample-questions')
      
    } catch (error) {
      console.error('Error saving sample question:', error);
      alert('Failed to save sample question.');
    }
  };



  return (
    <div className="wrapper"> 
    <div className="row">
    <SidebarPdf></SidebarPdf>
        <div className="container mt-2 col-md-9 col-lg-10">
        <h3>Add Question Template</h3>

<div className="class-info">
    <p><span><strong>Class:</strong>  {selectedClass}  </span></p>
    <p><span><strong>Subject:</strong>  {selectedSubject}  </span></p>
</div>

      <Form onSubmit={handleSubmit}>
        
        
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </Form.Group>
       
        <Form.Group className="mb-3">
          <Form.Label>Header Image</Form.Label>
          <Form.Control type="file" name="pdf" onChange={handleFileChange} accept=".pdf" />
        </Form.Group>

        {formData.id ? (
          <Button variant="primary" onClick={handleUpdate} >
            Update Sample Question
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Add Sample Question
          </Button>
        )}
        
      </Form>

      

     
      </div>
      </div>
    </div>
  );
};

export default AddQuestionTemplate;
