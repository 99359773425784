import React, { useState } from 'react';
import axios from 'axios';
import { newQuestionPdfUpload, fetchNewQuestionPdf } from '../../api';

const NewQuestionUpload = ({id}) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('pdf', file);

    try {
      const response = await newQuestionPdfUpload(id, formData)
   
      setMessage(response.data.message || 'File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Failed to upload file.');
    }
  };

  return (
    <div>
    
      <p><input type="file" onChange={handleFileChange} /></p>
      <button onClick={handleUpload}>Upload NewQuestion</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default NewQuestionUpload;
