// src/pages/Home.js
import React from 'react';


const Contact = () => {
  return (
    <>
   

  <header className="text-black text-center py-5">
    <div className="container">
      <h1>Contact Us</h1>
      <p className="lead">We love to hear from you! Get in touch with us for support or inquiries.</p>
    </div>
  </header>


  <section className="py-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mb-4">
          <h2 className="mb-4">Get in Touch</h2>
          <p style={{marginBottom:20}}>If you have any questions, suggestions, or need assistance, please use the contact form or the details below to reach out to us. We're here to help!</p>
          <ul className="list-unstyled">
            <li style={{marginBottom:20}}><strong>Email:</strong> info@studentgrows.com</li>
            <li style={{marginBottom:20}}><strong>Phone:</strong> +91 7838557578</li>
            <li style={{marginBottom:20}}><strong>Address:</strong> Plot No C-03 Sector 4, Greater Noida West 201306
            Uttar Pradesh, India</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <h2 className="mb-4">Contact Form</h2>
          <form>
            <div className="mb-3">
              <label for="name" className="form-label">Your Name</label>
              <input type="text" className="form-control" id="name" placeholder="Enter your name" required/>
            </div>
            <div className="mb-3">
              <label for="email" className="form-label">Your Email</label>
              <input type="email" className="form-control" id="email" placeholder="Enter your email" required/>
            </div>
            <div className="mb-3">
              <label for="subject" className="form-label">Subject</label>
              <input type="text" className="form-control" id="subject" placeholder="Enter subject" required/>
            </div>
            <div className="mb-3">
              <label for="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows="5" placeholder="Write your message" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>


  {/* <section className="bg-light py-5">
    <div className="container">
      <h3 className="text-center mb-4">Find Us</h3>
      <div className="ratio ratio-16x9">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8354345074697!2d-122.40139868468103!3d37.788496079756106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858064a3c3b54f%3A0xb1a3f4f2bd04f6e9!2sGoogleplex!5e0!3m2!1sen!2sus!4v1633979658001!5m2!1sen!2sus"
           allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  </section> */}
    </>
  );
};

export default Contact;
